// Packages & libraries
import loader from "vue-ui-preloader";
import languageBundle from "@kirschbaum-development/laravel-translations-loader!@kirschbaum-development/laravel-translations-loader";
import VueI18n from "vue-i18n";
// import * as localeCodes from "locale-codes";

// JS files
import "./assets/js/bootstrap";
import locale from "./assets/js/locale";
import "./assets/js/php-email-form-validation@3.5/validate";

// Vue.js
import Vue from "vue";
import VueRouter from "vue-router";

import router from "./router/index";
import App from "./App.vue";

[VueRouter, VueI18n, loader].forEach((x) => Vue.use(x));

const i18n = new VueI18n({
    locale: locale,
    messages: languageBundle,
});

new Vue({
    el: "#app",
    router,
    i18n,
    components: {
        App,
    },
});

Vue.prototype.primaryColor =
    localStorage.getItem("primaryColor") == null
        ? "#012970"
        : localStorage.getItem("primaryColor");

let offlineEn = languageBundle.en.alerts['You are offline']
let offlineAr = languageBundle.ar.alerts['You are offline']

if (locale == 'en' && localStorage.getItem("offline-en") == null) {
    localStorage.removeItem('offline-ar')
    localStorage.setItem("offline-en", offlineEn);
} else if (locale == 'ar' && localStorage.getItem("offline-ar") == null) {
    localStorage.removeItem('offline-en')
    localStorage.setItem("offline-ar", offlineAr);
}
