const routes = [
    {
        path: "/",
        component: () => import("../views/Index.vue"),
        name: "home",
    },
    // {
    //     path: "/offline",
    //     component: () => import("../views/Offline.vue"),
    //     name: "offline",
    // },
    // {
    //     path: "/posts",
    //     component: () => import("../views/Posts.vue"),
    //     name: "blog",
    // },
    // {
    //     path: "/posts/post",
    //     component: () => import("../views/Post.vue"),
    //     name: "post",
    // },
];

export default routes;
