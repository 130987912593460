// Detect and change language
let locale = "en";

if (localStorage.getItem("lang") == null) {
    locale = navigator.language.substr(0, 2);
} else {
    locale = localStorage.getItem("lang");
}

// RTL direction for some elements
if (locale == "ar") {
    $("html").attr("lang", "ar");
    $("html").attr("dir", "rtl");
}

export default locale
